module.exports = {
    /// 是否登录
    isLoginFromApp: function () {
        try {
            // eslint-disable-next-line no-undef
            if (isLogin && typeof isLogin === 'function') {
                // eslint-disable-next-line no-undef
                return isLogin();
            }
        } catch (e) {
            return false;
        }
        return false;
    },
    /// 查询是否已经领取
    whetherToReceiveRewardsFromApp:function (){
        try {
            // eslint-disable-next-line no-undef
            if (whetherToReceiveRewards && typeof whetherToReceiveRewards === 'function') {
                // eslint-disable-next-line no-undef
                return whetherToReceiveRewards();
            }
        } catch (e) {
            return false;
        }
        return false;
    },
    /// 提交数据
    submitDataToApp:function (json){
        try {
            // eslint-disable-next-line no-undef
            if (submitData && typeof submitData === 'function') {
                // eslint-disable-next-line no-undef
                return submitData(json);
            }
        } catch (e) {
            return null;
        }
        return null;
    }
}