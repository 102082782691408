import React from "react";
import {Result} from "antd-mobile";
import {useParams} from "react-router-dom";

class Error extends React.Component {
    render() {
        return <>
            <Result
                status='error'
                title='未登录'
                description='请先登录，在使用'
            />
        </>;
    }
}

export default Error;