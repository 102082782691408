import React from "react";
import {Result} from 'antd-mobile'

class Loading extends React.Component {
    render() {
        return <>
            <Result
                status='waiting'
                title='查询中'
                description='正在查询，请稍后'
            />
        </>;
    }
}

export default Loading;